/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useState } from 'react';
import { Link } from 'gatsby';
import cx from 'clsx';
import { StaticImage } from 'gatsby-plugin-image';
import Modal from 'react-modal';

// Images
import SpdImage from 'images/svg/spd.svg';
import ImpactImage from 'images/svg/impact.svg';
import BlurringIcon from 'images/svg/icon-blurring.svg';
import CorrectingIcon from 'images/svg/icon-correcting.svg';
import RestoringIcon from 'images/svg/icon-restoring.svg';
import ShrinkingIcon from 'images/svg/icon-shrinking.svg';
import RemodelingIcon from 'images/svg/icon-remodeling.svg';
import TighteningIcon from 'images/svg/icon-tightening.svg';
import FdaIsoIcon from 'images/svg/fda-and-iso-icon.svg';
import ImpactWithoutCircleImage from 'images/svg/impact-without-circle.svg';
import CloseIcon from 'images/svg/close-icon.svg';

// Components
import Layout from 'components/layout';
import VideoPlayer from 'components/video-player';
import DirectionalFlag from 'components/directional-flag';
import Slick from 'components/slick';
import Magnifier from 'components/magnifier';
import Popup from 'components/popup';
import ContactUs from 'components/contact-us';
import RewardBlock from 'components/reward-block';

// Styles
import * as styles from './index.module.scss';

const IndexPage = () => {
  const [isTipOpen, TipSetIsOpen] = useState<boolean>(false);
  const [isPulseShapeOpen, PulseShapeSetIsOpen] = useState<boolean>(false);
  const [isEnergyDistributionOpen, EnergyDistributionSetIsOpen] = useState<boolean>(false);

  const closeModal = useCallback(() => {
    TipSetIsOpen(false);
    PulseShapeSetIsOpen(false);
    EnergyDistributionSetIsOpen(false);
  }, []);

  const description = 'Reverso is a Fractional RF device that incorporates several innovative technological solutions, all intended to guarantee optimal clinical results skin resurfacing, tissue remodeling, stretch mark treatment and anti-aging treatment.';

  return (
    <Layout meta={{ description, image: 'https://intelis-public.s3.us-east-2.amazonaws.com/image-device.png' }}>
      <div className={styles.Wrapper}>
        <div className={styles.BlockVideo}>
          <div className={styles.BlockVideoPlayer}>
            <div className={styles.VideoDesktop}>
              <VideoPlayer src="//d3ghobcudqa5rz.cloudfront.net/hls/Video33/Video3.m3u8" autoPaused />
            </div>
            <div className={styles.VideoMobile}>
              <VideoPlayer src="//d3ghobcudqa5rz.cloudfront.net/hls/Video11/Video1.m3u8" autoPaused />
            </div>
          </div>
          <div className={styles.TextContainer}>
            <div className={styles.Text}>
              <div className={styles.ItemTextVideo}>
                <StaticImage width={509} height={95} src="../images/reverso.png" alt="Reverso" className={styles.ImageReverso} />
              </div>
              <div className={styles.ItemTextVideo}>
                <h4 className={styles.Title}>Reverse Skin Aging</h4>
              </div>
              <div className={styles.ItemTextVideo}>
                <p>
                  Reverso is a Fractional RF device,
                  offering highly efficient, patient-
                  tailored skin resurfacing.
                </p>
                <p>
                  The device incorporates several
                  innovative technological solutions,
                  all intended to guarantee optimal
                  clinical results while maintaining the
                  utmost safety.
                </p>
              </div>
            </div>
          </div>
        </div>
        <RewardBlock />
        <div className={styles.BlockTransparent}>
          <div className={styles.TransparentSectionImage}>
            <div className={styles.TransparentImage}>
              <div className={styles.ImageReversoDevice}>
                <StaticImage width={800} height={800} src="../images/reverso-device.png" alt="Reverso Device" />
              </div>
            </div>
          </div>
          <div className={styles.TransparentSectionText}>
            <div className={styles.TransparentText}>
              <div className={styles.TransparentElement}>
                <div className={styles.TransparentSpdContainer}>
                  <SpdImage width={527} height={181} />
                </div>
              </div>
              <div className={styles.TransparentElement}>
                <h2>Superior Technology</h2>
              </div>
              <div className={styles.TransparentElement}>
                <p>Speed, Power, Depth of Penetration</p>
              </div>
              <div className={styles.TransparentButton}>
                <button>
                  <Link to="/superior-technology/">
                    Read More
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.BlockVideoSecond}>
          <VideoPlayer src="//d3ghobcudqa5rz.cloudfront.net/hls/Video22/Video2.m3u8" loop />
        </div>
        <div className={styles.BlockImpact}>
          <div className={styles.ImpactContainer}>
            <div className={styles.Element}>
              <h2>Physiological Impact</h2>
            </div>
            <div className={styles.Element}>
              <h4>Optimal Skin Rejuvenation</h4>
            </div>
            <div className={styles.ImpactImageSection}>
              <div className={styles.ImpactImageWCircle}>
                <ImpactWithoutCircleImage />
              </div>
              <div className={styles.Col25}>
                <div className={styles.ImpactItemsContainer}>
                  <div className={styles.Element}>
                    <div className={styles.ItemCorrecting}>
                      <div className={styles.ImpactItemReverse}>
                        <div className={styles.ImpactIcon}>
                          <CorrectingIcon />
                        </div>
                        <div className={styles.ImpactContentText}>
                          <p>Correcting</p>
                          <p>
                            <strong>
                              uneven skin texture / tone
                            </strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.Element}>
                    <div className={styles.ItemRemodeling}>
                      <div className={styles.ImpactItemReverse}>
                        <div className={styles.ImpactIcon}>
                          <RemodelingIcon />
                        </div>
                        <div className={styles.ImpactContentText}>
                          <p>
                            Tissue remodeling-
                          </p>
                          <p>
                            <strong>
                              Skin rejuvenation
                            </strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.Element}>
                    <div className={styles.ItemBlurring}>
                      <div className={styles.ImpactItemReverse}>
                        <div className={styles.ImpactIcon}>
                          <BlurringIcon />
                        </div>
                        <div className={styles.ImpactContentText}>
                          <p>
                            Blurring
                          </p>
                          <p>
                            <strong>
                              stretch marks
                            </strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.Col50}>
                <ImpactImage className={styles.ImpactImageCircle} width="605" height="605" />
              </div>
              <div className={styles.Col25}>
                <div className={styles.ImpactItemsContainer}>
                  <div className={styles.Element}>
                    <div className={styles.ItemTightening}>
                      <div className={styles.ImpactItem}>
                        <div className={styles.ImpactIcon}>
                          <TighteningIcon />
                        </div>
                        <div className={styles.ImpactContentText}>
                          <p>
                            Tightening
                          </p>
                          <p>
                            <strong>
                              loose and sagging skin
                            </strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.Element}>
                    <div className={styles.ItemRestoring}>
                      <div className={styles.ImpactItem}>
                        <div className={styles.ImpactIcon}>
                          <RestoringIcon />
                        </div>
                        <div className={styles.ImpactContentText}>
                          <p>
                            Restoring
                          </p>
                          <p>
                            <strong>
                              skin firmness and elasticity
                            </strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.Element}>
                    <div className={styles.ItemShrinking}>
                      <div className={styles.ImpactItem}>
                        <div className={styles.ImpactIcon}>
                          <ShrinkingIcon />
                        </div>
                        <div className={styles.ImpactContentText}>
                          <p>
                            Shrinking
                          </p>
                          <p>
                            <strong>
                              enlarged pores
                            </strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.BlockSkinResurfacing}>
          <div className={styles.Container}>
            <div className={styles.Col50}>
              <StaticImage width={649} height={421} src="../images/reverso-after-treatment.png" alt="Skin Resurfacing" className={styles.ImageSkinResurfacing} />
            </div>
            <div className={styles.Col50}>
              <div className={styles.Wrap}>
                <div className={styles.Element}>
                  <h4>Deep Fractional RF</h4>
                </div>
                <div className={styles.Element}>
                  <h2>Skin Resurfacing & Remodeling</h2>
                </div>
                <div className={styles.Element}>
                  <p className={styles.Paragraph}>
                    The Reverso deep fractional RF causes deep and narrow tissue
                    ablation and contained protein coagulation.
                    The healing processes of this thermal destruction
                    result in skin rejuvenation and remodeling.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <button className={styles.Button}>
            <Link to="/deep-fractional-rf/">
              Read More
            </Link>
          </button>
        </div>
        <div className={styles.BlockExperience}>
          <div className={styles.Container}>
            <div className={styles.Wrap}>
              <div className={styles.ExperienceContentText}>
                <div className={styles.ExperienceTextContainer}>
                  <div className={styles.ExperienceText}>
                    <div className={styles.Element}>
                      <h2>Advanced User Experience</h2>
                    </div>
                    <div className={styles.Element}>
                      <p>
                        Reverso offers an advanced and intuitive user interface,
                        designed to optimize both user experience and the clinical results.
                      </p>
                    </div>
                    <div className={styles.Element}>
                      <p>
                        The device is controlled by a main touch screen (10.4 inch),
                        as well as a touch panel on the applicator.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.ExperienceUserInterface}>
            <div className={styles.ExperienceUserInterfaceContainer}>
              <div className={cx(styles.Col25, styles.ExperienceUserInterfaceFlexItem)}>
                <div className={cx(styles.Wrap, styles.ExperienceWrap)}>
                  <div className={styles.Element}>
                    <div className={styles.ExperienceTouchPanel}>
                      <DirectionalFlag direction="down">Touch Panel on the Applicator</DirectionalFlag>
                    </div>
                  </div>
                  <div className={styles.Element}>
                    <StaticImage width={254} height={174} src="../images/touch-panel.png" alt="Touch Panel" className={styles.ExperienceTouchImage} />
                  </div>
                  <div className={styles.Element}>
                    <div className={cx(styles.ExperienceItem, styles.ExperienceEnergyTuning)}>
                      <p>Energy Tuning</p>
                    </div>
                  </div>
                  <div className={styles.Element}>
                    <div className={cx(styles.ExperienceItem, styles.ExperiencePulseCounter)}>
                      <p>Pulse Counter</p>
                    </div>
                  </div>
                  <div className={styles.Element}>
                    <div
                      className={cx(
                        styles.ExperienceItem,
                        styles.CursorPointer,
                        styles.ExperienceTipManagement,
                      )}
                      onClick={() => TipSetIsOpen(!isTipOpen)}
                    >
                      <p>Tip Management Interface</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.Vol50}>
                <div className={styles.ExperienceInterfaceImage}>
                  <StaticImage width={548} height={770} src="../images/reverso-user-interface.png" alt="Reverso User Interface" />
                </div>
                <div className={styles.ExperienceInterfaceImageWithI}>
                  <StaticImage width={690} height={972} src="../images/reverso-interface-with-num.png" alt="Reverso User Interface" />
                </div>
              </div>
              <div className={styles.Col25}>
                <div className={styles.Wrap}>
                  <div className={styles.Element}>
                    <div className={cx(styles.ExperienceItem, styles.ExperienceRemoteConnectivity)}>
                      <p>Remote Connectivity</p>
                    </div>
                  </div>
                  <div className={styles.Element}>
                    <div
                      className={cx(
                        styles.ExperienceItem,
                        styles.CursorPointer,
                        styles.ExperiencePulseShape,
                      )}
                      onClick={() => PulseShapeSetIsOpen(!isPulseShapeOpen)}
                    >
                      <p>Pulse Shape Selection</p>
                    </div>
                  </div>
                  <div className={styles.Element}>
                    <div
                      className={cx(
                        styles.ExperienceItem,
                        styles.CursorPointer,
                        styles.ExperienceEnergyDistribution,
                      )}
                      onClick={() => EnergyDistributionSetIsOpen(!isEnergyDistributionOpen)}
                    >
                      <p>Energy Distribution Selection</p>
                    </div>
                  </div>
                  <div className={styles.Element}>
                    <div className={cx(styles.ExperienceItem, styles.ExperienceRepeatMode)}>
                      <p>Repeat Mode</p>
                    </div>
                  </div>
                  <div className={styles.Element}>
                    <div className={cx(styles.ExperienceItem, styles.ExperienceIncomingMessages)}>
                      <p>Incoming Messages</p>
                    </div>
                  </div>
                  <div className={styles.Element}>
                    <div className={cx(styles.ExperienceItem, styles.ExperienceTipInUse)}>
                      <p>Tip in Use</p>
                    </div>
                  </div>
                  <div className={styles.Element}>
                    <div className={cx(styles.ExperienceItem, styles.ExperienceSettings)}>
                      <p>Settings</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.ExperienceMobilePanel}>
                <DirectionalFlag direction="up">Touch Panel on the Applicator</DirectionalFlag>
              </div>
              <div className={styles.ExperienceElementorWrapper}>
                <div className={styles.Element}>
                  <div className={cx(
                    styles.ExperienceElementor,
                    styles.ExperienceElementorItem1,
                    styles.ExperienceNotModal,
                  )}
                  >
                    <p>
                      Remote Connectivity
                    </p>
                  </div>
                </div>
                <div className={styles.Element}>
                  <div
                    className={cx(
                      styles.ExperienceElementor,
                      styles.ExperienceElementorItem2,
                      styles.ExperienceModal,
                    )}
                    onClick={() => PulseShapeSetIsOpen(!isPulseShapeOpen)}
                  >
                    <p>
                      Pulse Shape Selection
                    </p>
                  </div>
                </div>
                <div className={styles.Element}>
                  <div
                    className={cx(
                      styles.ExperienceElementor,
                      styles.ExperienceElementorItem3,
                      styles.ExperienceModal,
                    )}
                    onClick={() => EnergyDistributionSetIsOpen(!isEnergyDistributionOpen)}
                  >
                    <p>
                      Energy Distribution Selection
                    </p>
                  </div>
                </div>
                <div className={styles.Element}>
                  <div className={cx(
                    styles.ExperienceElementor,
                    styles.ExperienceElementorItem4,
                    styles.ExperienceNotModal,
                  )}
                  >
                    <p>
                      Repeat Mode
                    </p>
                  </div>
                </div>
                <div className={styles.Element}>
                  <div className={cx(
                    styles.ExperienceElementor,
                    styles.ExperienceElementorItem5,
                    styles.ExperienceNotModal,
                  )}
                  >
                    <p>
                      Incoming Messages
                    </p>
                  </div>
                </div>
                <div className={styles.Element}>
                  <div className={cx(
                    styles.ExperienceElementor,
                    styles.ExperienceElementorItem6,
                    styles.ExperienceNotModal,
                  )}
                  >
                    <p>
                      Tip in Use
                    </p>
                  </div>
                </div>
                <div className={styles.Element}>
                  <div className={cx(
                    styles.ExperienceElementor,
                    styles.ExperienceElementorItem7,
                    styles.ExperienceNotModal,
                  )}
                  >
                    <p>
                      Settings
                    </p>
                  </div>
                </div>
                <div className={styles.Element}>
                  <div className={cx(
                    styles.ExperienceElementor,
                    styles.ExperienceElementorItem8,
                    styles.ExperienceNotModal,
                  )}
                  >
                    <p>
                      Energy Tuning
                    </p>
                  </div>
                </div>
                <div className={styles.Element}>
                  <div className={cx(
                    styles.ExperienceElementor,
                    styles.ExperienceElementorItem9,
                    styles.ExperienceNotModal,
                  )}
                  >
                    <p>
                      Pulse Counter
                    </p>
                  </div>
                </div>
                <div className={styles.Element}>
                  <div
                    className={cx(
                      styles.ExperienceElementor,
                      styles.ExperienceElementorItem10,
                      styles.ExperienceModal,
                    )}
                    onClick={() => TipSetIsOpen(!isTipOpen)}
                  >
                    <p>
                      Tip Management Interface
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            className={styles.Modal}
            bodyOpenClassName="modal--opened"
            shouldCloseOnOverlayClick
            onRequestClose={closeModal}
            isOpen={isTipOpen}
            ariaHideApp={false}
            style={{
              overlay: {
                zIndex: 20,
                backgroundColor: 'rgba(0, 0, 0, 0.75)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              },
              content: {
                position: 'relative',
                inset: '0',
                maxWidth: '1400px',
              },
            }}
          >
            <div className={styles.ModalWrapper}>
              <h4>Tip Management Interface</h4>
              <StaticImage src="../images/tip-management-interface.jpeg" alt="Tip Management Interface" width={124} height={603} />
            </div>
            <CloseIcon className={styles.Close} onClick={() => TipSetIsOpen(!isTipOpen)} />
          </Modal>
          <Modal
            className={cx(styles.Modal, styles.ModalShapeSelection)}
            bodyOpenClassName="modal--opened"
            shouldCloseOnOverlayClick
            onRequestClose={closeModal}
            isOpen={isPulseShapeOpen}
            ariaHideApp={false}
            style={{
              overlay: {
                zIndex: 20,
                backgroundColor: 'rgba(0, 0, 0, 0.75)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              },
              content: {
                position: 'relative',
                inset: '0',
                maxWidth: '1400px',
              },
            }}
          >
            <div className={styles.ModalWrapper}>
              <h4>Pulse Shape Selection</h4>
              <Slick color="black" />
            </div>

            <CloseIcon
              className={styles.Close}
              onClick={() => PulseShapeSetIsOpen(!isPulseShapeOpen)}
            />
          </Modal>
          <Modal
            className={cx(styles.Modal, styles.ModalDistribution)}
            bodyOpenClassName="modal--opened"
            shouldCloseOnOverlayClick
            onRequestClose={closeModal}
            isOpen={isEnergyDistributionOpen}
            ariaHideApp={false}
            style={{
              overlay: {
                zIndex: 20,
                backgroundColor: 'rgba(0, 0, 0, 0.75)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              },
              content: {
                position: 'relative',
                inset: '0',
                maxWidth: '1400px',
              },
            }}
          >
            <div className={styles.ModalWrapper}>
              <h4>Energy Distribution Patterns</h4>
              <div className={styles.GridColumnThree}>
                <div className={styles.Flex}>
                  <StaticImage src="../images/full-grid.png" alt="Full Grid" width={524} height={352} />
                  <h5>Full Grid</h5>
                </div>
                <div className={styles.Flex}>
                  <StaticImage src="../images/partial-grid.png" alt="Partial Grid" width={524} height={352} />
                  <h5>Partial Grid</h5>
                </div>
                <div className={styles.Flex}>
                  <StaticImage src="../images/alternating-lines.png" alt="Alternating Lines" width={524} height={352} />
                  <h5>Alternating Lines</h5>
                </div>
              </div>
            </div>
            <CloseIcon
              className={styles.Close}
              onClick={() => EnergyDistributionSetIsOpen(!isEnergyDistributionOpen)}
            />
          </Modal>
        </div>
        <div className={styles.BlockCustomizedTreatment}>
          <div className={styles.Container}>
            <div className={styles.ContentCustomizedTreatment}>
              <div className={styles.TreatmentSlick}>
                <Slick />
              </div>
              <div className={styles.Text}>
                <h2>Highly Customized Treatment</h2>
                <p>VPP - Variable Pulse Profile</p>
              </div>
              <button>
                <Link to="/highly-customized-treatment/">
                  Read More
                </Link>
              </button>
            </div>
          </div>
        </div>
        <div className={styles.BlockResult}>
          <div className={cx(styles.Container, styles.ResultContainer)}>
            <h4>Skin Resurfacing</h4>
            <h2>Results</h2>
            <div className={styles.GridColumnTwo}>
              <Magnifier
                text={(<p>2 months from a single treatment</p>)}
                children1={(
                  <>
                    <StaticImage src="../../images/result-before-1.jpeg" className={styles.MagnifierImage} alt="before" width={328} height={280} />
                    <StaticImage src="../../images/result-after-1.jpeg" className={styles.MagnifierImage} alt="after" width={328} height={280} />
                  </>
                )}
                modalImageBefore={(
                  <StaticImage src="../../images/result-before-huge-1.jpeg" className={styles.MagnifierImageModal} alt="before" width={570} height={369} />
                )}
                modalImageAfter={(
                  <StaticImage src="../../images/result-after-huge-1.jpeg" className={styles.MagnifierImageModal} alt="after" width={569} height={373} />
                )}
              />
              <Magnifier
                text={(<p>4 weeks from 2nd treatment</p>)}
                children1={(
                  <>
                    <StaticImage src="../../images/result-before-2.jpeg" className={styles.MagnifierImage} alt="before" width={328} height={280} />
                    <StaticImage src="../../images/result-after-2.jpeg" className={styles.MagnifierImage} alt="after" width={328} height={280} />
                  </>
                )}
                modalImageBefore={(
                  <StaticImage src="../../images/result-before-huge-2.jpeg" className={styles.MagnifierImageModal} alt="before" width={631} height={401} />
                )}
                modalImageAfter={(
                  <StaticImage src="../../images/result-after-huge-2.jpeg" className={styles.MagnifierImageModal} alt="after" width={648} height={401} />
                )}
              />
              <Magnifier
                text={(<p>4 weeks from 2nd treatment</p>)}
                children1={(
                  <>
                    <StaticImage src="../../images/result-before-3.jpeg" className={styles.MagnifierImage} alt="before" width={328} height={280} />
                    <StaticImage src="../../images/result-after-3.jpeg" className={styles.MagnifierImage} alt="after" width={328} height={280} />
                  </>
                )}
                modalImageBefore={(
                  <StaticImage src="../../images/result-before-huge-3.jpeg" className={styles.MagnifierImageModal} alt="before" width={620} height={462} />
                )}
                modalImageAfter={(
                  <StaticImage src="../../images/result-after-huge-3.jpeg" className={styles.MagnifierImageModal} alt="after" width={676} height={464} />
                )}
              />
              <Magnifier
                text={(<p>4 weeks after a single treatment</p>)}
                children1={(
                  <>
                    <StaticImage src="../../images/result-before-4.jpeg" className={styles.MagnifierImage} alt="before" width={328} height={280} />
                    <StaticImage src="../../images/result-after-4.jpeg" className={styles.MagnifierImage} alt="after" width={328} height={280} />
                  </>
                )}
                modalImageBefore={(
                  <StaticImage src="../../images/result-before-huge-4.jpeg" className={styles.MagnifierImageModal} alt="before" width={1407} height={1650} />
                )}
                modalImageAfter={(
                  <StaticImage src="../../images/result-after-huge-4.jpeg" className={styles.MagnifierImageModal} alt="after" width={1321} height={1674} />
                )}
              />
            </div>
            <button>
              <Link to="/before-and-after/">
                See more results
              </Link>
            </button>

          </div>
        </div>
        <div className={styles.BlockSpecifications}>
          <div className={styles.SpecificationContent}>
            <StaticImage
              src="../images/reverso-device-2.png"
              className={styles.ImageReversoDeviceTwo}
              alt="Reverso Device"
            />
            <h2>Technical Specifications</h2>
            <div className={styles.SpecificationsPopup}>
              <div className={styles.Col33}>
                <div className={cx(styles.SpecificationsWrap, styles.Wrap)}>
                  <Popup
                    text="Max Energy"
                    popupText={(
                      <p>
                        124 mJ/ pin
                      </p>
                  )}
                  />
                  <Popup
                    text="Power Input"
                    popupText={(
                      <p>
                        100-240V 50/60 Hz, 100VA
                      </p>
                  )}
                  />
                  <Popup
                    text="Touch Panel (x2)"
                    popupText={(
                      <p>
                        Touch Screen, 10.4 inch +
                        <br />
                        {' '}
                        Touch panel on applicator
                      </p>
                  )}
                  />
                </div>
              </div>
              <div className={styles.Col33}>
                <div className={cx(styles.SpecificationsWrap, styles.Wrap)}>
                  <Popup
                    text="Max Power"
                    popupText={(
                      <p>
                        10W/ pin
                      </p>
                  )}
                  />
                  <Popup
                    text="Disposable Tips"
                    popupText={(
                      <p>
                        Variable tips,
                        <br />
                        {' '}
                        44/88/176 pins
                      </p>
                  )}
                  />
                  <Popup
                    text="Dimensions"
                    popupText={(
                      <p>
                        330mm X 330mm X 330mm
                        <br />
                        {' '}
                        (WxDxH)
                      </p>
                  )}
                  />

                </div>
              </div>
              <div className={styles.Col33}>
                <div className={cx(styles.SpecificationsWrap, styles.Wrap)}>
                  <Popup
                    text="Frequency"
                    popupText={(
                      <p>
                        460KHz
                      </p>
                  )}
                  />
                  <Popup
                    text="Tip Area"
                    popupText={(
                      <p>
                        Approximately 2 cm²
                      </p>
                  )}
                  />
                  <Popup
                    text="Weight"
                    popupText={(
                      <p>
                        Body: 5 Kg
                        <br />
                        {' '}
                        Applicator: 0.2 Kg
                      </p>
                  )}
                  />
                </div>
              </div>
            </div>
            <div className={styles.Images}>
              <FdaIsoIcon />
            </div>

          </div>
        </div>
        <div className={styles.BlockContactUs}>
          <div className={styles.Container}>
            <ContactUs />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
