// extracted by mini-css-extract-plugin
export var Error = "contact-us-form-module--Error--FNKJv";
export var ErrorIcon = "contact-us-form-module--ErrorIcon---tuoQ";
export var ErrorText = "contact-us-form-module--ErrorText--xGtGp";
export var Form = "contact-us-form-module--Form--3cLCJ";
export var FormButton = "contact-us-form-module--FormButton--AMkL5";
export var IconLocation = "contact-us-form-module--IconLocation--g6Hzl";
export var ImageDevice = "contact-us-form-module--ImageDevice--KJbFm";
export var InfoSection = "contact-us-form-module--InfoSection--EBciq";
export var InfoText = "contact-us-form-module--InfoText--q1NEs";
export var Item = "contact-us-form-module--Item--1A0qK";
export var LoadingButton = "contact-us-form-module--LoadingButton--Ec7gP";
export var LoadingIcon = "contact-us-form-module--LoadingIcon--fGWEu";
export var Response = "contact-us-form-module--Response--Szy55";
export var moving = "contact-us-form-module--moving--p56Wk";