// extracted by mini-css-extract-plugin
export var Arrow = "directional-flag-module--Arrow--dzv2p";
export var ArrowLeft = "directional-flag-module--ArrowLeft--R2MAc";
export var ArrowRight = "directional-flag-module--ArrowRight--lEYuh";
export var Close = "directional-flag-module--Close--qN02H";
export var Down = "directional-flag-module--Down--J723O";
export var IsModal = "directional-flag-module--IsModal--KtV4e";
export var IsNotModal = "directional-flag-module--IsNotModal--MfFmd";
export var Left = "directional-flag-module--Left--0dzwI";
export var Line = "directional-flag-module--Line--e4WMF";
export var LineRight = "directional-flag-module--LineRight--q6Tbk";
export var Right = "directional-flag-module--Right--j2Pvu";
export var Up = "directional-flag-module--Up--tTaMK";
export var Wrapper = "directional-flag-module--Wrapper--Mi8yq";