/* eslint-disable no-nested-ternary */
import React, {
  FC, memo, useEffect, useState,
} from 'react';
import Slider from 'react-slick';

// Images
import FlatPulseImage from 'images/svg/flat-pulse-slick.svg';
import SuperPulseImage from 'images/svg/super-pulse-slick.svg';
import DoublePulseImage from 'images/svg/double-pulse-slick.svg';
import DoubleSpeedScanImage from 'images/svg/double-speed-scan-slick.svg';
import QuadroPulseImage from 'images/svg/quadro-pulse-slick.svg';
import FlatPulseBlackImage from 'images/svg/flat-pulse-black.svg';
import SuperPulseBlackImage from 'images/svg/super-pulse-black.svg';
import DoublePulseBlackImage from 'images/svg/double-pulse-black.svg';
import DoubleSpeedScanBlackImage from 'images/svg/double-speed-scan-black.svg';
import QuadroPulseBlackImage from 'images/svg/quadro-pulse-black.svg';

// Styles
import * as styles from './slick.module.scss';

interface IProps {
  color?: string
}

const Slick: FC<IProps> = memo(({ color }: IProps) => {
  const [slidesToShow, setSlidesToShow] = useState<number>(4);

  useEffect(() => {
    const handleWindowSizeChange = () => {
      const { innerWidth: width } = window;

      setSlidesToShow(width <= 1024 ? width <= 775 ? 1 : 2 : 4);
    };

    handleWindowSizeChange();

    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return (
    <Slider
      arrows
      dots={false}
      infinite
      speed={500}
      slidesToShow={slidesToShow}
      slidesToScroll={1}
      className={styles.Wrapper}
    >
      <div className={styles.Item}>
        <div className={styles.Image}>
          {color
            ? <FlatPulseBlackImage />
            : <FlatPulseImage />}
        </div>
        <div className={styles.Text}>
          <h5>Flat pulse</h5>
        </div>
      </div>
      <div className={styles.Item}>
        <div className={styles.Image}>
          {color
            ? <SuperPulseBlackImage />
            : <SuperPulseImage />}
        </div>
        <div className={styles.Text}>
          <h5>Super pulse</h5>
        </div>
      </div>
      <div className={styles.Item}>
        <div className={styles.Image}>
          {color
            ? <DoublePulseBlackImage />
            : <DoublePulseImage />}
        </div>
        <div className={styles.Text}>
          <h5>Double Pulse</h5>
        </div>
      </div>
      <div className={styles.Item}>
        <div className={styles.Image}>
          {color
            ? <DoubleSpeedScanBlackImage />
            : <DoubleSpeedScanImage />}
        </div>
        <div className={styles.Text}>
          <h5>Double Speed Scan</h5>
        </div>
      </div>
      <div className={styles.Item}>
        <div className={styles.Image}>
          {color
            ? <QuadroPulseBlackImage />
            : <QuadroPulseImage />}
        </div>
        <div className={styles.Text}>
          <h5>Quadro Pulse</h5>
        </div>
      </div>
    </Slider>
  );
});

export default Slick;
