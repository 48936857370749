/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC, ReactNode, useCallback, useState } from 'react';
import Modal from 'react-modal';
import cx from 'clsx';

// Images
import CloseIcon from 'images/svg/close-icon.svg';

// Styles
import * as styles from './directional-flag.module.scss';

interface IProps {
  children: string;
  modalChildren?: ReactNode;
  direction: 'up' | 'right' | 'down' | 'left';
  isModal?: boolean;
}

const DirectionalFlag: FC<IProps> = ({
  children, modalChildren, direction, isModal = false,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleModal = useCallback(() => setIsOpen((cIsOpen) => !cIsOpen), []);
  const closeModal = useCallback(() => setIsOpen(false), []);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className={cx(styles.Wrapper, isModal ? styles.IsModal : styles.IsNotModal)}
      onClick={isModal ? toggleModal : undefined}
    >
      <span className={cx({
        [styles.Arrow]: isModal,
        [styles.ArrowRight]: isModal && direction === 'left',
        [styles.ArrowLeft]: isModal && direction === 'right'
      })}
      />
      {direction === 'down' ? <span className={styles.Down} /> : null}
      {direction === 'up' ? <span className={styles.Up} /> : null}
      {direction === 'left' ? <span className={styles.Left} /> : null}
      {direction === 'right' ? <span className={styles.Right} /> : null}
      <i className={cx({
        [cx(styles.Line, styles.LineRight)]: direction === 'right'
      })} />
      <i className={cx({
        [cx(styles.Line, styles.LineLeft)]: direction === 'left'
      })} />
      <p>
        {children}
      </p>
      <Modal
        className={styles.Modal}
        bodyOpenClassName="modal--opened"
        shouldCloseOnOverlayClick={true}
        onRequestClose={closeModal}
        isOpen={isOpen}
        ariaHideApp={false}
        style={{
          overlay: {
            zIndex: 20,
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
          },
          content: {
            position: 'relative',
            inset: '10px 15%',
            borderRadius: '50px',
            maxWidth: '1400px',
          },
        }}
      >
        <CloseIcon className={styles.Close} onClick={toggleModal} />
        {modalChildren}
      </Modal>
    </div>
  );
};

export default DirectionalFlag;
