import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

// Styles
import * as styles from './reward-block.module.scss';

const RewardBlock = () => (
  <div className={styles.Wrapper}>
    <div className={styles.Block}>
      <h2 className={styles.Title}>Red Dot Design Award 2022</h2>
      <div className={styles.Flex}>
        <StaticImage src="../../images/reddot-reward.jpg" className={styles.ImageReddotReward} alt="Reddot reward" />
        <StaticImage src="../../images/reddot-reward-device.jpg" width={900} alt="Reddot reward device" />
      </div>
      <p className={styles.Text}>
        The Indiba Reverso is awarded the &quot;Product
        Design&quot; at the Red Dot Design Award 2022
      </p>
      <div className={styles.Button}>
        <button>
          <Link to="https://www.red-dot.org/project/reverso-57110">
            Read More
          </Link>
        </button>
      </div>
    </div>
  </div>
);

export default RewardBlock;
