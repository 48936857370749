import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

// Components
import InfoForm from 'components/info-form/info-form';
import ContactUsForm from 'components/contact-us-form/contact-us-form';

// Images
import IconLocation from 'images/svg/icon-location-2.svg';
import IconPhoneCall from 'images/svg/icon-phone-call-2.svg';
import IconEmail from 'images/svg/icon-email.svg';

// Styles
import * as styles from './contact-us.module.scss';

const ContactUs = () => (
  <div className={styles.Wrapper}>
    <div className={styles.Block}>
      <ContactUsForm needInfo>
        <h2>Contact Us</h2>
        <p>Please get in touch, and we will reply as soon as possible.</p>
      </ContactUsForm>
    </div>
    <div className={styles.Block}>
      <InfoForm>
        <div className={styles.Item}>
          <IconLocation color="#0070A6" fill="#0070A6" className={styles.IconLocation} />
          <p className={styles.InfoText}>Moianès, 13 – Pol. Ind. Can Casablanques 08192 Sant Quirze del Vallès (Barcelona) Spain</p>
        </div>
        <div className={styles.Item}>
          <IconPhoneCall color="#0070A6" fill="#0070A6" />
          <div className={styles.InfoIconPhone} />
          <p className={styles.InfoText}>+34 93 265 55 22</p>
        </div>
        <div className={styles.Item}>
          <IconEmail color="#0070A6" fill="#0070A6" />
          <div className={styles.InfoIconMail} />
          <p className={styles.InfoText}>
              cs@indiba.com.
            <a href="https://www.google.com/maps/place/INDIBA+S.A./@41.5404434,2.0791719,17z/data=!3m1!4b1!4m5!3m4!1s0x12a4a2de6ff95eb7:0xa4c1a00354d41029!8m2!3d41.5404394!4d2.0813606?shorturl=1" target="_blank" rel="noreferrer">Directions.</a>
          </p>
        </div>
      </InfoForm>
      <StaticImage width={800} height={586} src="../../images/contact-us-device.png" alt="" className={styles.Image} />
    </div>
  </div>
);

export default ContactUs;
