/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, ReactNode } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import HubspotForm from 'react-hubspot-form';

// Images
import IconLocation from 'images/svg/icon-location-2.svg';
import IconPhoneCall from 'images/svg/icon-phone-call-2.svg';
import IconEmail from 'images/svg/icon-email.svg';

// Styles
import * as styles from './contact-us-form.module.scss';

interface IProps {
  children?: ReactNode;
  needInfo?: boolean;
  needImage?: boolean;
}

const ContactUsForm: FC<IProps> = ({ children, needInfo = false, needImage = false }) => (
  <form className={styles.Form}>
    {children}
    <HubspotForm
      region="na1"
      portalId="7243710"
      formId="cc30c556-457d-4efd-9fd4-e7987af95468"
    />
    {needInfo
      ? (
        <div className={styles.InfoSection}>
          <div className={styles.Item}>
            <IconLocation color="#0070A6" fill="#0070A6" className={styles.IconLocation} />
            <p className={styles.InfoText}>
            Moianès, 13 - Pol. Ind. Can Casablanques 08192 Sant Quirze del Vallès (Barcelona) Spain
            </p>
          </div>
          <div className={styles.Item}>
            <IconPhoneCall color="#0070A6" fill="#0070A6" />
            <div className={styles.InfoIconPhone} />
            <p className={styles.InfoText}>+34 93 265 55 22</p>
          </div>
          <div className={styles.Item}>
            <IconEmail color="#0070A6" fill="#0070A6" />
            <div className={styles.InfoIconMail} />
            <p className={styles.InfoText}>
            cs@indiba.com.
              <a href="https://www.google.com/maps/place/INDIBA+S.A./@41.5404434,2.0791719,17z/data=!3m1!4b1!4m5!3m4!1s0x12a4a2de6ff95eb7:0xa4c1a00354d41029!8m2!3d41.5404394!4d2.0813606?shorturl=1" target="_blank" rel="noreferrer">Directions.</a>
            </p>
          </div>
        </div>
      )
      : ''}

    {needImage
      ? <StaticImage width={664} height={379} src="../../images/image-device.png" alt="" className={styles.ImageDevice} />
      : ''}
  </form>
);

export default ContactUsForm;
