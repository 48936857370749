import React, { FC, useEffect } from 'react';
import Hls from 'hls.js';

interface IProps {
  src: string;
  video?: HTMLVideoElement;
  type?: string;
  isVideoChild?: boolean;
}

const HLSSource: FC<IProps> = ({ src, video, type }) => {
  useEffect(() => {
    const hls = new Hls();
    const cVideo = video as HTMLVideoElement;

    if (src && cVideo && Hls.isSupported()) {
      hls.loadSource(src);
      hls.attachMedia(cVideo);
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        cVideo.play().catch(() => {});
      });
    }

    return () => {
      if (hls) {
        hls.destroy();
      }
    };
  }, [src, video]);

  return (
    <source
      src={src}
      type={type || 'application/x-mpegURL'}
    />
  );
};

export default HLSSource;
