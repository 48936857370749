// extracted by mini-css-extract-plugin
export var BlockContactUs = "index-module--BlockContactUs--NhXE2";
export var BlockCustomizedTreatment = "index-module--BlockCustomizedTreatment--Ik4nu";
export var BlockExperience = "index-module--BlockExperience--sGBys";
export var BlockImpact = "index-module--BlockImpact--GVLJU";
export var BlockResult = "index-module--BlockResult--KsHLF";
export var BlockSkinResurfacing = "index-module--BlockSkinResurfacing--JcSkx";
export var BlockSpecifications = "index-module--BlockSpecifications--wJCK8";
export var BlockTransparent = "index-module--BlockTransparent--ZxFvI";
export var BlockVideo = "index-module--BlockVideo--mEZTc";
export var BlockVideoPlayer = "index-module--BlockVideoPlayer--hM7cO";
export var BlockVideoSecond = "index-module--BlockVideoSecond--FTpoz";
export var Button = "index-module--Button--Dp19n";
export var Close = "index-module--Close--O9AOL";
export var Col25 = "index-module--Col25--03oRc";
export var Col33 = "index-module--Col33--v-0U8";
export var Col50 = "index-module--Col50--8BTeU";
export var Container = "index-module--Container--nB3SL";
export var Content = "index-module--Content--kXFVs";
export var ContentCustomizedTreatment = "index-module--ContentCustomizedTreatment--MwjvS";
export var Element = "index-module--Element--Beqm+";
export var ExperienceContentText = "index-module--ExperienceContentText--yFQNC";
export var ExperienceElementor = "index-module--ExperienceElementor--BEDh8";
export var ExperienceElementorItem1 = "index-module--ExperienceElementorItem1--arVmb";
export var ExperienceElementorItem10 = "index-module--ExperienceElementorItem10--0I-QD";
export var ExperienceElementorItem2 = "index-module--ExperienceElementorItem2--KGbmE";
export var ExperienceElementorItem3 = "index-module--ExperienceElementorItem3--jQW4i";
export var ExperienceElementorItem4 = "index-module--ExperienceElementorItem4--c0S2S";
export var ExperienceElementorItem5 = "index-module--ExperienceElementorItem5--1+Ipw";
export var ExperienceElementorItem6 = "index-module--ExperienceElementorItem6--TVzsl";
export var ExperienceElementorItem7 = "index-module--ExperienceElementorItem7--AuY1F";
export var ExperienceElementorItem8 = "index-module--ExperienceElementorItem8--QT5BF";
export var ExperienceElementorItem9 = "index-module--ExperienceElementorItem9--Er9G6";
export var ExperienceElementorWrapper = "index-module--ExperienceElementorWrapper--unGWu";
export var ExperienceEnergyDistribution = "index-module--ExperienceEnergyDistribution--6IqMT";
export var ExperienceEnergyTuning = "index-module--ExperienceEnergyTuning--xi4IY";
export var ExperienceIncomingMessages = "index-module--ExperienceIncomingMessages--p67f1";
export var ExperienceInterfaceImage = "index-module--ExperienceInterfaceImage--V-nbj";
export var ExperienceInterfaceImageWithI = "index-module--ExperienceInterfaceImageWithI--rcd3y";
export var ExperienceItem = "index-module--ExperienceItem--IdUDY";
export var ExperienceMobilePanel = "index-module--ExperienceMobilePanel--b5uCm";
export var ExperienceModal = "index-module--ExperienceModal--Q-7ck";
export var ExperienceNotModal = "index-module--ExperienceNotModal--oilpO";
export var ExperiencePulseCounter = "index-module--ExperiencePulseCounter--qRsD2";
export var ExperiencePulseShape = "index-module--ExperiencePulseShape--chJ8D";
export var ExperienceRemoteConnectivity = "index-module--ExperienceRemoteConnectivity--Idevs";
export var ExperienceRepeatMode = "index-module--ExperienceRepeatMode--vWvsC";
export var ExperienceSettings = "index-module--ExperienceSettings--vsEMo";
export var ExperienceText = "index-module--ExperienceText--x26E+";
export var ExperienceTextContainer = "index-module--ExperienceTextContainer--NKz5n";
export var ExperienceTipInUse = "index-module--ExperienceTipInUse--J70US";
export var ExperienceTipManagement = "index-module--ExperienceTipManagement--m3IXR";
export var ExperienceTouchImage = "index-module--ExperienceTouchImage---yptx";
export var ExperienceTouchPanel = "index-module--ExperienceTouchPanel--pqZ+e";
export var ExperienceUserInterface = "index-module--ExperienceUserInterface--NXtTm";
export var ExperienceUserInterfaceContainer = "index-module--ExperienceUserInterfaceContainer--ysQJ9";
export var ExperienceUserInterfaceFlexItem = "index-module--ExperienceUserInterfaceFlexItem--yG7HN";
export var ExperienceWrap = "index-module--ExperienceWrap---gtUd";
export var GridColumnThree = "index-module--GridColumnThree--FZBo+";
export var GridColumnTwo = "index-module--GridColumnTwo--Ipbuo";
export var ImageReverso = "index-module--ImageReverso--y4yx9";
export var ImageReversoDevice = "index-module--ImageReversoDevice--THubb";
export var ImageReversoDeviceTwo = "index-module--ImageReversoDeviceTwo---bz3w";
export var ImageSkinResurfacing = "index-module--ImageSkinResurfacing--9Z88H";
export var ImageTouchPanel = "index-module--ImageTouchPanel--wxDLf";
export var ImageTreatmentBackground = "index-module--ImageTreatmentBackground--Qz6jz";
export var ImageUserInterface = "index-module--ImageUserInterface---tXXE";
export var Images = "index-module--Images--pXR+N";
export var ImpactContainer = "index-module--ImpactContainer--Ew-cf";
export var ImpactContentText = "index-module--ImpactContentText--NwEgb";
export var ImpactIcon = "index-module--ImpactIcon--4JjHN";
export var ImpactImageCircle = "index-module--ImpactImageCircle--Gh-wH";
export var ImpactImageSection = "index-module--ImpactImageSection--9xk57";
export var ImpactImageWCircle = "index-module--ImpactImageWCircle--m1gZq";
export var ImpactItem = "index-module--ImpactItem--toED5";
export var ImpactItemReverse = "index-module--ImpactItemReverse--c2nNV";
export var ImpactItemsContainer = "index-module--ImpactItemsContainer--p3Rj3";
export var ItemBlurring = "index-module--ItemBlurring--0U62u";
export var ItemCorrecting = "index-module--ItemCorrecting--1LsUz";
export var ItemEnergySelection = "index-module--ItemEnergySelection--PWpud";
export var ItemEnergyTuning = "index-module--ItemEnergyTuning--HBJoB";
export var ItemIncomingMessages = "index-module--ItemIncomingMessages--3Hsse";
export var ItemPulseCounter = "index-module--ItemPulseCounter--QA544";
export var ItemPulseSelection = "index-module--ItemPulseSelection--CQc6a";
export var ItemRemodeling = "index-module--ItemRemodeling--zb4jY";
export var ItemRemoteConnectivity = "index-module--ItemRemoteConnectivity--FK89O";
export var ItemRepeatMode = "index-module--ItemRepeatMode--AOV+o";
export var ItemRestoring = "index-module--ItemRestoring--7izwS";
export var ItemSettings = "index-module--ItemSettings--ehlOJ";
export var ItemShrinking = "index-module--ItemShrinking--XaaW4";
export var ItemText = "index-module--ItemText--VfhGb";
export var ItemTextVideo = "index-module--ItemTextVideo--BO0nT";
export var ItemTightening = "index-module--ItemTightening--ljfEa";
export var ItemTipInUse = "index-module--ItemTipInUse--X7XLQ";
export var ItemTipManagement = "index-module--ItemTipManagement--LOh8q";
export var ItemTouchPanel = "index-module--ItemTouchPanel--QFwVv";
export var Line = "index-module--Line--Y6DGh";
export var MagnifierImage = "index-module--MagnifierImage--P11Lx";
export var MagnifierImageModal = "index-module--MagnifierImageModal--9y7v5";
export var MagnifierImageWrapper = "index-module--MagnifierImageWrapper--eeQ56";
export var Modal = "index-module--Modal--y6VvO";
export var ModalDistribution = "index-module--ModalDistribution--FF-OQ";
export var ModalShapeSelection = "index-module--ModalShapeSelection--6JM-Z";
export var ModalWrapper = "index-module--ModalWrapper--9zgBq";
export var Paragraph = "index-module--Paragraph--eZb2Z";
export var ResultContainer = "index-module--ResultContainer--MuKO1";
export var SpecificationContent = "index-module--SpecificationContent--YnXwY";
export var SpecificationsPopup = "index-module--SpecificationsPopup--9+B+F";
export var SpecificationsWrap = "index-module--SpecificationsWrap--7A0+X";
export var Text = "index-module--Text---9xek";
export var TextContainer = "index-module--TextContainer--TnsJi";
export var TransparentButton = "index-module--TransparentButton--MLJpj";
export var TransparentElement = "index-module--TransparentElement--KIOSU";
export var TransparentImage = "index-module--TransparentImage--gIrdz";
export var TransparentSectionImage = "index-module--TransparentSectionImage--wBp0G";
export var TransparentSectionText = "index-module--TransparentSectionText--vwuN7";
export var TransparentSpdContainer = "index-module--TransparentSpdContainer--VS7IS";
export var TransparentText = "index-module--TransparentText--TcM1v";
export var TreatmentSlick = "index-module--TreatmentSlick--Ej5fv";
export var VideoDesktop = "index-module--VideoDesktop--jiBxG";
export var VideoMobile = "index-module--VideoMobile--J6xbi";
export var Wrap = "index-module--Wrap--LKob6";
export var Wrapper = "index-module--Wrapper--u6zqU";