import React, { FC } from 'react';
import * as styles from './info-form.module.scss';

const InfoForm: FC = ({ children }) => (
  <div className={styles.Form}>
    {children}
  </div>
);

export default InfoForm;
