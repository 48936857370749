/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, ReactNode, useCallback, useState } from 'react';
import cx from 'clsx';

// Images
import PlusIcon from 'images/svg/plus-icon.svg';
import MinusIcon from 'images/svg/minus-icon.svg';

// Styles
import * as styles from './popup.module.scss';

interface IProps {
  text: string;
  popupText: ReactNode
}

const Popup: FC<IProps> = ({
  text,
  popupText,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggleModal = useCallback(() => setIsOpen(!isOpen), []);

  return (
    <div className={cx(styles.Wrapper, isOpen ? styles.Wrapper__Opened : styles.Wrapper__Closed)}>
      <div className={styles.ClickableSection} onClick={toggleModal}>
        <p>{text}</p>
        {isOpen ? <MinusIcon className={styles.Icon} /> : <PlusIcon className={styles.Icon} />}
      </div>
      {popupText}
    </div>
  );
};

export default Popup;
