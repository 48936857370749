import React, {
  FC, memo, Ref, useCallback, useRef,
} from 'react';
import { Player } from 'video-react';

// Components
import HLSSource from './hls-source';

// Styles
import * as styles from './video-player.module.scss';

interface IProps {
  src: string;
  autoPaused?: boolean;
  loop?: boolean;
  type?: string;
}

const VideoPlayer: FC<IProps> = memo(({
  src, autoPaused = false, loop = false, type,
}: IProps) => {
  const playerRef = useRef<Player>(null);

  const setRef = useCallback((ref: Ref<Player>) => {
    playerRef.current = ref;

    if (ref && autoPaused) {
      playerRef.current.subscribeToStateChange((state) => {
        if (state.currentTime > state.duration - 0.5 && !state.paused) {
          playerRef.current.pause();
        }
      });
    }
  }, []);

  return (
    <Player
      ref={setRef}
      autoPlay
      loop={loop}
      muted
      playsInline
      className={styles.Player}
    >
      <HLSSource src={src} isVideoChild type={type} />
    </Player>
  );
});

export default VideoPlayer;
